import React, { useState } from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import ReactFlagsSelect from "react-flags-select";

export default function RegionSwitcher({className}) {
  const langToCountryCode = {
    "en_US": "US",
    "en_CA": "CA",
  };

  const { language, changeLanguage } = useI18next();
  const [selected, setSelected] = useState(langToCountryCode[language]);
  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  const handleChange = (country) => {
    const langCode = getKeyByValue(langToCountryCode, country)
    changeLanguage(langCode)
  }

  return (
    <ReactFlagsSelect
      countries={["US", "CA"]}
      showSelectedLabel={false}
      showOptionLabel={false}
      fullWidth={false}
      placeholder="Country"
      selected={selected}
      onSelect={(code) => handleChange(code)}
      className={className}
    />
  );
}
