import React, { useEffect } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import * as styles from "./layout.module.css";
import Header from "./header";
import Footer from "./footer";

// US - free terminal CTA
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FreeTerminalCTA from "./FreeTerminalCTA";

export default function Layout({ url, children }) {
  const { language, originalPath } = useI18next();

  useEffect(() => {
    if (language == "en_US" && !["/", "/get-quote/"].includes(originalPath)) {
      toast(<FreeTerminalCTA />);
    }
  }, [language]);

  return (
    <main>
      <Header gradientBg={url == "/"} />
      <div className={styles.layoutContent}>
        {" "}
        <ToastContainer
          position="top-right"
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        {children}
      </div>
      <Footer />
    </main>
  );
}
