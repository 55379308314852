// extracted by mini-css-extract-plugin
export var footerAddress = "footer-module--footerAddress--75WI3";
export var footerFinePrint = "footer-module--footerFinePrint--2-u+W";
export var footerLeft = "footer-module--footerLeft--3Tbme";
export var footerLinkColumn = "footer-module--footerLinkColumn--yulCZ";
export var footerLinkHeader = "footer-module--footerLinkHeader--mKxGd";
export var footerLinkItem = "footer-module--footerLinkItem--a9k4Y";
export var footerLogo = "footer-module--footerLogo--4AE4R";
export var footerParent = "footer-module--footerParent--Oi2H0";
export var footerRight = "footer-module--footerRight--Hfefo";
export var footerRoot = "footer-module--footerRoot--jks7m";
export var footerSocials = "footer-module--footerSocials--GRDZj";