import React, { useState, useEffect, useRef } from "react";
import * as styles from "./NavItem.module.css";
import { useI18next } from "gatsby-plugin-react-i18next";

export default function NavItem({ item }) {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownWrapper = useRef();
  const { language } = useI18next();

  const toggleDropdown = (e) => {
    e.preventDefault();
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (e) => {
    console.log("clicking anywhere");
    if (dropdownWrapper.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowMenu(false);
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);
  
  if (item.regions && !item.regions.includes(language)) return null;
  return (
    <div className={styles.root} ref={dropdownWrapper}>
      <a
        className={styles.navLink}
        href={item.src ? item.src : ""}
        onClick={item.children ? toggleDropdown : undefined}
      >
        {item.title}
      </a>
      {showMenu && (
        <div className={styles.dropdown}>
          {item.children.map((child) =>
            child.regions && !child.regions.includes(language) ? null : (
              <a className={styles.dropdownItem} href={child.src}>
                {child.title}
              </a>
            )
          )}
        </div>
      )}
    </div>
  );
}
