const column1 = [
  {
    title: "Solutions",
    header: true,
  },
  {
    title: "Smart terminals",
    link: "/solutions/smart-terminals"
  },
  {
    title: "Standard terminals",
    link: "/solutions/standard-terminals"
  },
  {
    title: "E-commerce solutions",
    link: "/solutions/ecomm-solutions"
  },
];

const column2 = [
  { title: "Prices", header: true },
  {
    title: "Interchange",
    link: "/pricing/interchange"
  },
];

const column3 = [
  { title: "Services", header: true },
  { title: "POS system", link: "/services/pos"},
  { title: "Business loans", link: "/services/business-loans" },
  { title: "Leases", link: "/services/leases"},
];

const column4 = [
  { title: "Company", header: true },
  { title: "About us", link: "/about-us"},
  { title: "Testimonials", link: "/testimonials"},
];

export { column1, column2, column3, column4 };
