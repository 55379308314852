import React from "react";
import * as styles from "./freeTerminalCta.module.css";

import desk3500 from "../../images/desk3500_front.png";

const FreeTerminalCTA = ({ closeToast, toastProps }) => (
  <div
    // style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    className={styles.root}
  >
    <img className={styles.readerImg} src={desk3500} />
    <h3>Get a free terminal</h3>
    <a href="/get-quote" style={{ alignSelf: "flex-start", textDecoration: 'none' }}>
      <div className={styles.ctaButton}>Sign up now</div>
    </a>
  </div>
);

export default FreeTerminalCTA;
