import React from "react";
import * as styles from "./footer.module.css";
import logo from "../images/logo-full.png";
import { column1, column2, column3, column4 } from "./footerLinks";

// social media icons
import instagram from "../images/instagram.svg";
import facebook from "../images/facebook.svg";

export default function Footer({ children }) {
  const renderEachLink = (_, i) => (
    <a
      key={`link${i}`}
      className={`${styles.footerLinkItem} ${
        _.header ? styles.footerLinkHeader : ""
      }`}
      href={_.link || undefined}
    >
      {_.title}
    </a>
  );

  return (
    <div className={styles.footerRoot}>
      <footer className={styles.footerParent}>
        <div className={styles.footerLeft}>
          <img className={styles.footerLogo} src={logo} width="120px" />
          <div className={styles.footerAddress}>
            <span className="bold-text gradient-text">My EZ Solutions</span>
            <p>
              <span>5589 Royalmount Ave</span>
              <span>Mount Royal</span>
              <span>Quebec H4P1J3</span>
              <span>Canada</span>
            </p>
          </div>
        </div>
        <div className={styles.footerRight}>
          <div className={styles.footerLinkColumn}>
            {column1.map(renderEachLink)}
          </div>
          <div className={styles.footerLinkColumn}>
            {column2.map(renderEachLink)}
          </div>
          <div className={styles.footerLinkColumn}>
            {column3.map(renderEachLink)}
          </div>
          <div className={styles.footerLinkColumn}>
            {column4.map(renderEachLink)}
          </div>
        </div>
      </footer>
      <div className={styles.footerSocials}>
        <a href="https://www.facebook.com/My-EZ-Solutions-104324644826505">
          <img src={facebook}></img>
        </a>
        <a href="https://instagram.com/myezsolution">
          <img src={instagram}></img>
        </a>
      </div>
    </div>
  );
}
