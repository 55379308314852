import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as styles from "./header.module.css";
import useScrollBlock from "../utils/useScrollBlock";
import NAV_ITEMS from "../data/navItems";
import NavItem from "./NavItem";
import RegionSwitcher from "./RegionSwitcher";

export default function Header({ gradientBg }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  // dont scroll when mobile nav menu open
  useEffect(() => {
    showMobileMenu ? blockScroll() : allowScroll();
  }, [showMobileMenu]);

  const menuOpenClass = showMobileMenu ? styles.mobileMenuOpen : "";
  return (
    <header
      style={{ background: gradientBg ? "#008ffd" : "#222222" }}
      className={styles.header}
    >
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <svg
        className={`${styles.menuBtn} ${menuOpenClass}`}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
        />
      </svg>
      <nav
        className={styles.navMenuMobile}
        style={{ display: showMobileMenu ? "flex" : "none" }}
      >
        <svg
          className={styles.mobileNavClose}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
          />
        </svg>
        {NAV_ITEMS.map((item) => (
          <NavItem item={item} />
        ))}
      </nav>
      <nav className={styles.navMenu}>
        {NAV_ITEMS.map((item) => (
          <NavItem item={item} />
        ))}
      </nav>
      <RegionSwitcher className={styles.regionSwitch} />
    </header>
  );
}
