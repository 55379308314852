const NAV_ITEMS = [
  {
    title: "Home",
    src: "/",
  },
  {
    title: "Solutions",
    children: [
      { title: "Smart terminals", src: "/solutions/smart-terminals", regions: ['en_CA']},
      { title: "Standard terminals", src: "/solutions/standard-terminals", regions: ['en_CA'] },
      { title: "E-comm solutions", src: "/solutions/ecomm-solutions" },
      {
        title: "Advantageous Pricing",
        src: "/solutions/advantageous-pricing",
        regions: ["en_US"],
      },
      {
        title: "Surcharge program",
        src: "/solutions/surcharge-program",
        regions: ["en_US"],
      },
    ],
  },
  {
    title: "Terminals",
    children: [
      { title: "Smart terminals", src: "/solutions/smart-terminals" },
      { title: "Standard terminals", src: "/solutions/standard-terminals" },
    ],
    regions: ["en_US"],
  },
  { title: "Interchange", src: "/pricing/interchange" },
  {
    title: "Services",
    children: [
      { title: "POS System", src: "/services/pos" },
      { title: "Business loans", src: "/services/business-loans" },
      { title: "Leases", src: "/services/leases" },
    ],
  },
  {
    title: "Company",
    children: [
      { title: "About us", src: "/about-us" },
      { title: "Testimonials", src: "/testimonials" },
    ],
  },
];

export default NAV_ITEMS;
